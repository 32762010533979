import { render, staticRenderFns } from "./interests.vue?vue&type=template&id=0fc40108&scoped=true&"
import script from "./interests.vue?vue&type=script&lang=js&"
export * from "./interests.vue?vue&type=script&lang=js&"
import style0 from "./interests.vue?vue&type=style&index=0&id=0fc40108&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fc40108",
  null
  
)

export default component.exports