<template>
  <div class="box">
    <div class="top">
      <div class="top-xbox">
        <div class="name">
          {{ userInfo.username }} <img src="@/img/vip/vip.png" alt="" />
        </div>
        <div>年卡会员</div>
      </div>
      <div class="time">会员到期：{{ userInfo.userVip.expireDate }}</div>
      <img src="@/img/vip/pay.png" class="pay" @click="order" />
    </div>
    <div class="title">会员尊享权益</div>
    <div class="box-top">
      <div class="hint-title">查看精准测额结果</div>
      <div class="hint-nr">精准匹配适合产品</div>
      <van-button class="button" to="pipeiChanPin">去看看</van-button>
    </div>
    <div class="box-bottom">
      <div class="bottom-left" @click="toInfo('report')">
        <div class="bottom-title">查看企业融资指导建议</div>
        <div class="bottom-nr">精准匹配适合产品</div>
      </div>
      <div class="bottom-right">
        <div class="xbox1" @click="toInfo('chat')">
          <div class="bottom-title">联系专属顾问</div>
          <div class="bottom-nr">在线答疑</div>
        </div>
        <div class="xbox2" @click="toInfo('course')">
          <div class="bottom-title">贷款课程学习</div>
          <div class="bottom-nr">企业主必学课程</div>
        </div>
      </div>
    </div>
    <div v-html="alipayWap" ref="alipayWap"></div>
  </div>
</template>
<script src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
import url from "@/service/url-config";

export default {
  data() {
    return {
      userInfo: {
        userPhotoFullPath: require("@/img/my/user-photo.png"),
      },
      vipInfo: {},
      alipayWap: "",
    };
  },
  filters: {
    date(val) {
      let date = new Date(val);
      let y = date.getFullYear(); // 年
      let MM = date.getMonth() + 1; // 月
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate(); // 日
      d = d < 10 ? "0" + d : d;
      let h = date.getHours(); // 时
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes(); // 分
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds(); // 秒
      s = s < 10 ? "0" + s : s;
      var timer = y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
      return timer;
    },
  },
  created() {
    this.$http.get(url.userInfo).then((res) => {
      this.userInfo = res.data;
      if (!this.userInfo.userPhotoFullPath) {
        this.userInfo.userPhotoFullPath = require("@/img/my/user-photo.png");
      }
    });
    this.getVip();
  },
  methods: {
    toInfo(url) {
      this.$router.push({
        path: url,
      });
    },
    //获取vip列表
    getVip() {
      this.$http.get(url.getVipList).then((res) => {
        this.vipInfo = res.data[0];
      });
    },
    //获取订单
    order() {
      this.$http
        .get(url.saveUserOrder + `?vipId=${this.vipInfo.vipId}`)
        .then((res) => {
          this.pay(res.data);
        });
    },
    //支付
    pay(order) {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.$http
          .post(url.aaaa, {
            amount: order.realPrice, //金额
            description: order.vipName, //订单描述
            purpose: 1, //  订单用途 传1
            productType: 1, //产品类型  传2
            busOrderId: order.orderNum, // 业务订单号
          })
          .then((res) => {
            WeixinJSBridge.invoke(
              "getBrandWCPayRequest",
              // res.data,
              {
                appId: res.data.appid,
                nonceStr: res.data.noncestr,
                package: res.data.package,
                paySign: res.data.paySign,
                signType: res.data.signType,
                timeStamp: res.data.timestamp,
              },
              function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                }
              }
            );
          });
      } else {
        this.$http
          .post(url.wapPayAli, {
            amount: order.realPrice, //金额
            description: order.vipName, //订单描述
            purpose: 2, //  订单用途 传1
            productType: 2, //产品类型  传2
            busOrderId: order.orderNum, // 业务订单号
          })
          .then((res) => {
            this.alipayWap = res.data;
            this.$nextTick(() => {
              this.$refs.alipayWap.children[0].submit();
            });
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  // background: url("../../img/vip/no-bg.png");
  // background: #080409;
  background: #202028;
  background-size: 100%;
  min-height: 100vh;
  // text-align: center;
  .top {
    width: 375px;
    height: 221px;
    background: url("../../img/vip/quanyi-bg1.png");
    background-size: 100%;
    position: relative;
    .top-xbox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 80px;
      left: 37px;
      width: 300px;
      color: #e7c19f;
      font-size: 12px;
      .name {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: bold;
        background: linear-gradient(0deg, #d19c69 0%, #ead2af 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        img {
          width: 39px;
          height: 22px;
        }
      }
    }
    .time {
      color: #e7c19f;
      font-size: 13px;
      position: absolute;
      bottom: 50px;
      left: 38px;
    }
    .pay {
      width: 65px;
      height: 25px;
      position: absolute;
      right: 36px;
      bottom: 50px;
    }
  }
  .title {
    color: #e7bf9b;
    font-size: 21px;
    padding: 22px;
  }
  .box-top {
    width: 345px;
    height: 104px;
    background: url("../../img/vip/quanyi-bg2.png");
    background-size: 100%;
    margin: 0 auto;
    position: relative;
    .hint-title {
      color: #191514;
      font-size: 14px;
      position: absolute;
      top: 28px;
      left: 97px;
    }
    .hint-nr {
      color: #dab082;
      font-size: 13px;
      position: absolute;
      top: 58px;
      left: 97px;
    }
    .button {
      width: 81px;
      height: 30px;
      background: #ffffff;
      border-radius: 15px;
      position: absolute;
      top: 40px;
      right: 15px;
    }
  }
  .box-bottom {
    width: 345px;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    .bottom-left {
      width: 168px;
      height: 200px;
      background: url("../../img/vip/quanyi-bg3.png") no-repeat;
      background-size: 100%;
    }
    .bottom-right {
      width: 170px;
      margin-left: 9px;
      .xbox1 {
        width: 170px;
        height: 98px;
        background: url("../../img/vip/quanyi-bg4.png");
        background-size: 100%;
      }
      .xbox2 {
        width: 170px;
        height: 98px;
        background: url("../../img/vip/quanyi-bg5.png");
        background-size: 100%;
        margin-top: 4px;
      }
    }
    .bottom-title {
      color: #191514;
      font-size: 14px;
      padding-top: 29px;
      padding-left: 11px;
    }
    .bottom-nr {
      color: #dab082;
      font-size: 13px;
      padding-left: 11px;
      margin-top: 10px;
    }
  }
}
</style>
